// translateService.js


// export const getPresignedUrl = async ({ file, user, targetLanguageName }) => {
//     const response = await axios.post('https://ctue105m5h.execute-api.eu-central-1.amazonaws.com/video_processor', {
//         fileName: file.name,
//         fileType: file.type,
//         userAuthId: user.uid,
//         targetLanguage: targetLanguageName,
//     }, {
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });
//     return response.data.url;
// };

// export const uploadFile = async ({ file, presignedUrl }) => {
//     try {
//         await axios.put(presignedUrl, file, {
//             headers: {
//                 'Content-Type': file.type,
//             },
//         });
//         console.log('File upload successful');
//         // You may then proceed with the translation process...
//     } catch (error) {
//         console.error('File upload failed:', error);
//         throw error; // Rethrow to handle in the calling function
//     }
// };

/// /src/components/translateService.js
import axios from 'axios';

// Function to sanitize the file name
const sanitizeFileName = (originalName) => {
    // Split the file name into base name and extension
    const lastDotIndex = originalName.lastIndexOf('.');
    let baseName = originalName;
    let extension = '';

    if (lastDotIndex !== -1) {
        baseName = originalName.substring(0, lastDotIndex);
        extension = originalName.substring(lastDotIndex); // Includes the dot
    }

    // Remove spaces, dots, and commas from the base name
    const sanitizedBaseName = baseName.replace(/[ .,]+/g, '_'); // Replaces with underscores

    // Optionally, you can remove all unwanted characters without replacing
    // const sanitizedBaseName = baseName.replace(/[ .,]/g, '');

    // Ensure that the sanitized base name is not empty
    const finalBaseName = sanitizedBaseName || 'file';

    // Reconstruct the sanitized file name
    return finalBaseName + extension;
};

// Function to get a pre-signed URL from Lambda
const getPresignedUrl = async ({ userAuthId, fileName, fileType, sourceLanguage, targetLanguage }) => {
    try {
        const payload = {
            userAuthId: userAuthId,
            fileName: fileName,
            fileType: fileType,
            sourceLanguage: sourceLanguage,
            targetLanguage: targetLanguage
        };

        const response = await axios.post(
            'https://lgrno7rhj7.execute-api.eu-central-1.amazonaws.com/uploadURL_generator',
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );

        return response.data.url; // The pre-signed URL for uploading the file
    } catch (error) {
        console.error('Error getting pre-signed URL:', error.response?.data || error.message);
        throw error.response?.data || { error: 'Failed to get pre-signed URL' };
    }
};

export const submitFile = async ({ user, file, sourceLanguageName, targetLanguageName, voice, ethicalTranslation, duration }) => {
    try {
        // Sanitize the file name by removing spaces, dots, and commas (except for the extension)
        const sanitizedFileName = sanitizeFileName(file.name);
        // Alternatively, to remove characters without replacing:
        // const sanitizedFileName = sanitizeFileName(file.name);

        // Optionally, inform the user about the sanitized file name
        // alert(`Your file name has been sanitized to: ${sanitizedFileName}`);

        // Create a new File object with the sanitized name
        const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });

        // Get the pre-signed URL from Lambda using the sanitized file name
        const presignedUrl = await getPresignedUrl({
            userAuthId: user.uid,
            fileName: sanitizedFileName,
            fileType: sanitizedFile.type,
            sourceLanguage: sourceLanguageName,
            targetLanguage: targetLanguageName
        });

        // Upload the sanitized file to S3 using the pre-signed URL
        const uploadResponse = await axios.put(presignedUrl, sanitizedFile, {
            headers: {
                'Content-Type': sanitizedFile.type,
            }
        });

        console.log('File uploaded successfully:', uploadResponse);

        // After successful upload, notify the backend about the new file with duration
        const notifyPayload = {
            userAuthId: user.uid,
            fileName: sanitizedFileName,
            sourceLanguage: sourceLanguageName,
            targetLanguage: targetLanguageName,
            voice: voice,
            ethicalTranslation: ethicalTranslation,
            duration: duration // Include duration
        };
        console.log("notifyPayload: ", notifyPayload);

        const notifyResponse = await axios.post(
            'https://ctue105m5h.execute-api.eu-central-1.amazonaws.com/video_processor',
            notifyPayload,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        console.log('File processing initiated:', notifyResponse.data);
        return notifyResponse.data; // Return the server response
    } catch (error) {
        console.error('File upload failed:', error.response?.data || error.message);
        throw error.response?.data || { error: 'File upload failed' };
    }
};
