// /src/components/Tooltip.js

import React from 'react';
import ReactDOM from 'react-dom';
import './Tooltip.css'; 
const Tooltip = ({ children, onClose }) => {
    return ReactDOM.createPortal(
        <div className="tooltip-overlay" onClick={onClose}>
            <div className="tooltip-content" onClick={(e) => e.stopPropagation()}>
                {children}
                <button className="tooltip-close-button" onClick={onClose}>×</button>
            </div>
        </div>,
        document.body
    );
};

export default Tooltip;
