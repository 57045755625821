// 'topUpBalance.js';
import React, { useState, useEffect } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// import i18n from './i18n'; // Import the i18n configuration

function TopUpBalance({user, client_id}) {
    const { t } = useTranslation();
    const [showPayPal, setShowPayPal] = useState(false);
    const [amount, setAmount] = useState("0");

    useEffect(() => {
      console.log("showPayPal changed:", showPayPal);
    }, [showPayPal]);

    const handleAmountChange = (e) => {
      setAmount(e.target.value);
    };

    const handleTopUpClick = () => {
      const value = parseFloat(amount);
      if (value < 2) {
        alert(t('please_top_up_by_at_least'));
      } else {
        setShowPayPal(true);
      }
    };

    const submitPayment = async (user, order_id, client_id) => {
        console.log("user.uid: ", user.uid)
        console.log("client_id: ", client_id)
        try {
            const response = await axios.post('https://nb9tbjlv0c.execute-api.eu-central-1.amazonaws.com/payment_processor', {
                user_auth_id: user.uid,
                order_id: order_id,
                client_id: client_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(t('payment_successful'), response);
        } catch (error) {
            console.error(t('payment_failed'), error);
            throw error;
        }
    };

    return (
      <div>
        <input
          type="number"
          value={amount}
          onChange={handleAmountChange}
          min="2"
          style={{ width: '50px', fontSize:'20px', marginRight:"10px" }}
        />
        <button onClick={handleTopUpClick} style={{ height:"30px", fontSize:'15px', alignItems: 'center', paddingBottom:'25px'  }}>{t('top_up_balance')}</button>
        {showPayPal && (
          <div style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom:0,
            paddingLeft:100,
            paddingRight:100,
            backgroundColor: "black",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "auto",
            zIndex: 1000,
            paddingTop: "100px"
        }}>
            <PayPalButtons
              style={{
                layout: 'vertical',
                color: 'blue',
                shape: 'rect',
                label: 'pay',
                size: 'responsive',
                tagline: false
              }}
              createOrder={(data, actions) => actions.order.create({
                purchase_units: [{
                  amount: {
                    value: amount,
                    currency_code: "USD"
                  }
                }]
              })}
              onApprove={async (data, actions) => actions.order.capture().then(async details => {
                console.log(t('transaction_successful'), details);
                try {
                  await submitPayment(user, details.id, client_id);
                  setShowPayPal(false);
                } catch (error) {
                  console.error(t('payment_failed'), error);
                  setShowPayPal(false);
                }
              })}
              onError={(err) => {
                console.error("PayPal Checkout onError", err);
                setShowPayPal(false);
              }}
            />
          </div>
        )}
      </div>
    );
  }

  export default TopUpBalance;
