// src/components/ProcessedFiles.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ProcessedFiles.css';

const ProcessedFiles = ({ zips, handleDownloadZip, formatDate }) => {
    const { t } = useTranslation();

    return (
        <div className="processed-files-section">
            <h2 className="section-title">{t('processedFiles', { defaultValue: "Processed Files" })}</h2>
            <div className="processed-files-container">
                {zips.length > 0 ? (
                    zips.map(zip => (
                        <div key={zip.id} className="processed-file-card">
                            <div className="file-info">
                                <p className="file-title">{zip.title}</p>
                                {zip.date && <p className="file-date">{formatDate(zip.date)}</p>}
                            </div>
                            <button onClick={() => handleDownloadZip(zip.url, zip.title)} className="download-button">
                                {t('download')}
                            </button>
                        </div>
                    ))
                ) : (
                    <p className="no-processed-files"></p>
                )}
            </div>
        </div>
    );
};

export default ProcessedFiles;
