// src/components/processedZips.js
import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';

const useProcessedZip = (user, onZipUpdate, isNewZipAdded) => {
    const [zips, setZips] = useState([]);

    useEffect(() => {
        if (!user || !user.uid) return;

        const db = getDatabase();
        const zipRef = ref(db, `users/${user.uid}/processed_videos`);

        const unsubscribe = onValue(zipRef, (snapshot) => {
            const data = snapshot.val();
            const zipArray = [];

            if (data) {
                Object.keys(data).forEach(key => {
                    const zipUrl = data[key].url;
                    if (zipUrl && zipUrl.includes('.zip')) {
                        const filename = zipUrl.split('/').pop().split('?')[0]; // Remove any query parameters
                        const formattedTitle = filename.replace(/_\d{4}-\d{2}-\d{2}\.zip$/, '').replace(/_/g, ' ');

                        // Extract date from filename
                        const datePattern = /(\d{4}-\d{2}-\d{2})/;
                        const matches = filename.match(datePattern);
                        let zipDate = null;
                        if (matches) {
                            zipDate = new Date(matches[1]);
                        }

                        zipArray.push({
                            id: key,
                            url: zipUrl,
                            title: formattedTitle || 'Zip Archive',
                            date: zipDate // Store the extracted date
                        });
                    }
                });

                // Reverse the array to ensure latest files come first
                zipArray.reverse();
            }

            console.log("Reversed zipArray with dates:", zipArray);
            setZips(zipArray);
            if (zipArray.length > 0) {
                onZipUpdate(zipArray);
                isNewZipAdded();
            }
        });

        return () => unsubscribe(); // Cleanup on unmount
    }, [user, onZipUpdate, isNewZipAdded]);

    return zips;
};

export default useProcessedZip;
