// /src/Translate.js

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { languageOptions } from './constants/languageOptions';
import { extractLanguageName } from './utils/languageUtils';
import LanguageSelector from './components/LanguageSelector';
import useProcessedZip from './components/processedZips';
import { submitFile } from './components/translateService';
import ProgressBar from './components/ProgressBar';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle, FaMicrophone, FaSyncAlt, FaUpload } from 'react-icons/fa';
import Tooltip from './components/Tooltip';
import './Translate.css';
import ProcessedFiles from './components/ProcessedFiles'; // Import the separate component

// Helper function to format duration in hh:mm:ss
const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);
    return [
        hours > 0 ? hours : null,
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
    ].filter(Boolean).join(':');
};

// Function to extract duration from a media file
const extractMediaDuration = (file, mediaElementRef) => {
    return new Promise((resolve, reject) => {
        const fileURL = URL.createObjectURL(file);
        let mediaElement;

        // Determine whether to use Audio or Video element
        if (file.type.startsWith('audio/')) {
            mediaElement = new Audio();
        } else if (file.type.startsWith('video/')) {
            mediaElement = document.createElement('video');
            mediaElement.style.display = 'none'; // Hide the video element
            document.body.appendChild(mediaElement); // Append to load metadata
            mediaElementRef.current = mediaElement; // Store reference for cleanup
        } else {
            reject(new Error('Unsupported file type'));
            return;
        }

        mediaElement.src = fileURL;

        // Listen for metadata to be loaded
        const onLoadedMetadata = () => {
            const duration = mediaElement.duration;
            URL.revokeObjectURL(fileURL);
            if (mediaElement.tagName.toLowerCase() === 'video') {
                document.body.removeChild(mediaElement);
            }
            resolve(duration);
        };

        // Handle errors
        const onError = (e) => {
            console.error('Error loading media metadata', e);
            URL.revokeObjectURL(fileURL);
            if (mediaElement.tagName.toLowerCase() === 'video') {
                document.body.removeChild(mediaElement);
            }
            reject(new Error('Failed to load media metadata'));
        };

        mediaElement.addEventListener('loadedmetadata', onLoadedMetadata);
        mediaElement.addEventListener('error', onError);
    });
};

function Translate({ user }) {
    const { t } = useTranslation();

    // State variables
    const getLanguageFromCache = (key, defaultLang) => {
        const storedLang = localStorage.getItem(key);
        return storedLang || defaultLang;
    };

    const [sourceLanguage, setSourceLanguage] = useState(() => getLanguageFromCache('sourceLanguage', 'es'));
    const [targetLanguage, setTargetLanguage] = useState(() => getLanguageFromCache('targetLanguage', 'en'));
    const [loading, setLoading] = useState(false);
    const [startProgress, setStartProgress] = useState(false);
    const [progressComplete, setProgressComplete] = useState(false);
    const [processingDuration, setProcessingDuration] = useState(0); // State variable for processing duration
    const [selectedAudio, setSelectedAudio] = useState("");
    const [isMultivoice, setIsMultivoice] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isEthicalTranslation, setIsEthicalTranslation] = useState(false); // New state
    const [showEthicalTooltip, setShowEthicalTooltip] = useState(false); // New state
    const [uploadedFile, setUploadedFile] = useState(null); // New state for uploaded file
    const [uploadError, setUploadError] = useState(null); // New state for upload errors
    const [fileDuration, setFileDuration] = useState(null); // New state for file duration
    const [isDurationLoading, setIsDurationLoading] = useState(false); // New state for duration loading
    const audioPlayerRef = useRef(null);
    const mediaElementRef = useRef(null); // Ref for media element used for duration extraction

    useEffect(() => {
        localStorage.setItem('sourceLanguage', sourceLanguage);
        localStorage.setItem('targetLanguage', targetLanguage);
    }, [sourceLanguage, targetLanguage]);

    const audioFiles = [
        { name: 'Californian', file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Falloy.mp3?alt=media&token=cb3cbbcd-ea11-45ad-8976-8aa946325855' },
        { name: 'Classic Chris', file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Fecho.mp3?alt=media&token=13f50780-320a-4c33-ab34-c0bfb34ce09f' },
        { name: 'Drama Coach', file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Ffable.mp3?alt=media&token=0744df84-7b2c-4ad9-8308-7344181d62a4' },
        { name: "Harmony Holly", file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Fnova.mp3?alt=media&token=e0c10c93-d2fe-438e-9e1e-57745a14ef56' },
        { name: 'The Guardian', file: '/audio/onyx.mp3' },
        { name: 'Deep Diva', file: '/audio/shimmer.mp3' }
    ];

    const handleAudioChange = (event) => {
        const selectedFile = event.target.value;
        setSelectedAudio(selectedFile);
        if (selectedFile !== 'multivoice' && audioPlayerRef.current) {
            audioPlayerRef.current.src = selectedFile;
            audioPlayerRef.current.load();
            audioPlayerRef.current.play()
                .then(() => console.log('Audio playing'))
                .catch(error => {
                    console.error('Error playing audio:', error);
                    alert(t('playbackFailed', { error: error.message }));
                });
        } else {
            // If multivoice is selected, stop any playing audio
            if (audioPlayerRef.current) {
                audioPlayerRef.current.pause();
                audioPlayerRef.current.src = '';
            }
        }
        if (selectedFile) {
            setIsMultivoice(false); // Uncheck multivoice if a single voice is selected
        }
    };

    const handleReplayNoSound = () => {
        if (isMultivoice) {
            alert(t('multivoiceReplayNotAvailable'));
            return;
        }

        if (!selectedAudio) {
            alert(t('selectVoice'));
        } else {
            audioPlayerRef.current.play()
                .catch(error => {
                    console.error('Error replaying audio:', error);
                    alert(t('playbackFailed', { error: error.message }));
                });
        }
    };

    const handleMultivoiceToggle = () => {
        setIsMultivoice(prev => !prev);
        if (!isMultivoice) {
            setSelectedAudio(""); // Clear selected audio if multivoice is toggled on
            if (audioPlayerRef.current) {
                audioPlayerRef.current.pause();
                audioPlayerRef.current.src = '';
            }
        }
    };

    const handleEthicalTranslationToggle = () => {
        setIsEthicalTranslation(prev => !prev);
    };

    const handleNewVideo = useCallback((video) => {
        if (video) {
            setProgressComplete(true);
        }
    }, []);

    const finishProgressBar = useCallback(() => {
        setProgressComplete(true);
        setTimeout(() => {
            setStartProgress(false);
            setProgressComplete(false);
        }, 500);
    }, []);

    const handleDownloadZip = (url, title) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${title}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const zips = useProcessedZip(user, handleNewVideo, finishProgressBar);

    // New formatDate function
    const formatDate = (date) => {
        return date.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
    };

    // Handler for file uploads using react-dropzone
    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        // Handle accepted files
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setUploadedFile(file);
            setUploadError(null);
            setFileDuration(null); // Reset previous duration
            setIsDurationLoading(true); // Start loading indicator

            // Extract duration
            extractMediaDuration(file, mediaElementRef)
                .then(duration => {
                    setFileDuration(duration);
                    console.log(`Extracted duration: ${duration} seconds`);
                    setIsDurationLoading(false); // Stop loading indicator
                })
                .catch(error => {
                    console.error('Error extracting duration:', error);
                    setFileDuration(null);
                    setIsDurationLoading(false); // Stop loading indicator
                });
        }

        // Handle rejected files
        if (fileRejections.length > 0) {
            const rejection = fileRejections[0];
            const errorMessages = rejection.errors.map(e => e.message).join(', ');
            setUploadError(errorMessages);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'video/*': [],
            'audio/*': []
        },
        maxFiles: 1,
        maxSize: 100 * 1024 * 1024 // 100 MB limit
    });

    const handleTranslate = async () => {
        if (!user) {
            alert(t('pleaseSignIn'));
            return;
        }

        if (!uploadedFile) {
            alert(t('provideInput'));
            return;
        }

        if (!isMultivoice && !selectedAudio) {
            alert(t('selectVoiceChoice'));
            return;
        }

        setLoading(true);

        const sourceLanguageName = extractLanguageName(languageOptions.find(option => option.value === sourceLanguage)?.label || '');
        const targetLanguageName = extractLanguageName(languageOptions.find(option => option.value === targetLanguage)?.label || '');

        let voiceName = '';

        if (isMultivoice) {
            voiceName = 'multivoice'; // Set voiceName to 'multivoice'
        } else {
            voiceName = audioFiles.find(audio => audio.file === selectedAudio)?.name || '';
        }

        try {
            // Use the updated submitFile function
            const response = await submitFile({
                user,
                file: uploadedFile,
                sourceLanguageName,
                targetLanguageName,
                voice: voiceName,
                ethicalTranslation: isEthicalTranslation,
                duration: fileDuration // Include duration
            });

            setProcessingDuration(response.video_duration); // Set processing duration
            setStartProgress(true);
            setProgressComplete(false);
        } catch (error) {
            console.error("Error:", error);

            if (error.error === 'Insufficient credits') {
                alert(t('insufficientCredits'));
            } else {
                alert(error.error);
            }
            setLoading(false);
            return;
        }
        setLoading(false);
    };

    // ... other imports and code ...

return (
    <div className="translate-page">
        <div className="translate-container">
            <div className="translate-upload-container">
                {/* Drag-and-Drop Area */}
                <div className="translate-upload-inputs">
                    <div {...getRootProps()} className={`translate-dropzone ${isDragActive ? 'active' : ''}`}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>{t('dropTheFileHere')}</p> :
                                <p>{uploadedFile ? t('fileSelected', { name: uploadedFile.name }) : t('dragAndDropOrClick')}</p>
                        }
                        <FaUpload className="translate-upload-icon" />
                    </div>
                    {/* Display upload error */}
                    {uploadError && <p className="translate-error-message">{uploadError}</p>}
                    {/* Display selected file details */}
                    {uploadedFile && (
                        <div className="translate-file-details">
                            <p>{t('uploadedFile')}: {uploadedFile.name}</p>
                            {isDurationLoading ? (
                                <p>{t('extractingDuration')}</p>
                            ) : fileDuration !== null ? (
                                <p>{t('duration')}: {formatDuration(fileDuration)}</p>
                            ) : (
                                <p className="translate-error-message">{t('durationExtractionFailed')}</p>
                            )}
                            <button onClick={() => setUploadedFile(null)} className="translate-remove-file-button">
                                {t('remove')}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* Language Selector Component */}
            <div className="translate-language-selector-container">
                <LanguageSelector
                    targetLanguage={targetLanguage}
                    setTargetLanguage={setTargetLanguage}
                    sourceLanguage={sourceLanguage}
                    setSourceLanguage={setSourceLanguage}
                />
            </div>

            {/* Voice and Ethical Translation Selection */}
            <div className="translate-voice-ethical-selection">
                {/* Multivoice Toggle */}
                <div className="translate-multivoice-toggle">
                    <label className="translate-multivoice-label">
                        <input
                            type="checkbox"
                            checked={isMultivoice}
                            onChange={handleMultivoiceToggle}
                        />
                        {t('multivoice')}
                    </label>
                    <FaInfoCircle
                        className="translate-info-icon"
                        onClick={() => setShowTooltip(!showTooltip)}
                    />
                    {showTooltip && (
                        <Tooltip onClose={() => setShowTooltip(false)}>
                            {t('multivoiceInfo')}
                        </Tooltip>
                    )}
                </div>

                {/* Ethical Translation Toggle */}
                <div className="translate-ethical-translation-toggle">
                    <label className="translate-ethical-label">
                        <input
                            type="checkbox"
                            checked={isEthicalTranslation}
                            onChange={handleEthicalTranslationToggle}
                        />
                        {t('ethicalTranslation')}
                    </label>
                    <FaInfoCircle
                        className="translate-info-icon"
                        onClick={() => setShowEthicalTooltip(!showEthicalTooltip)}
                    />
                    {showEthicalTooltip && (
                        <Tooltip onClose={() => setShowEthicalTooltip(false)}>
                            {t('ethicalTranslationInfo')}
                        </Tooltip>
                    )}
                </div>
            </div>

            {/* Voice Selection and Replay */}
            <div className="translate-voice-selection-dropdown-replay">
                {!isMultivoice && (
                    <>
                        <select
                            value={selectedAudio}
                            onChange={handleAudioChange}
                            className="translate-voice-select"
                        >
                            <option value="">{t('selectVoice')}</option>
                            {audioFiles.map(audio => (
                                <option key={audio.file} value={audio.file}>{audio.name}</option>
                            ))}
                        </select>
                        <button
                            onClick={handleReplayNoSound}
                            disabled={!selectedAudio}
                            className="translate-replay-button"
                        >
                            <FaSyncAlt />
                            {t('replay')}
                        </button>
                    </>
                )}
            </div>

            {/* Progress Bar and Translate Button */}
            {startProgress ? (
                <div className="translate-progress-bar-wrapper">
                    <span className="translate-do-not-refresh-text">
                        {/* {t('doNotRefresh')}
                        {processingDuration > 0 && (
                            <span className="translate-estimated-time">
                                {t('estimatedTime', { time: formatDuration(processingDuration) })}
                            </span>
                        )} */}
                    </span>
                    <ProgressBar
                        duration={processingDuration || 50} // Use processingDuration here
                        startProgress={startProgress}
                        completeImmediately={progressComplete}
                    />
                </div>
            ) : (
                <button
                    className="translate-button"
                    onClick={handleTranslate}
                    disabled={loading}
                >
                    {loading ? t('translating') : (
                        <>
                            <FaMicrophone />
                            {t('startTranslation')}
                        </>
                    )}
                </button>
            )}
        </div>

        {/* Processed Files Section */}
        <ProcessedFiles
            zips={zips}
            handleDownloadZip={handleDownloadZip}
            formatDate={formatDate}
        />

        <audio ref={audioPlayerRef} controls hidden></audio>
    </div>
);}


export default Translate;
